import { useCallback, useEffect, useState } from 'react';

const useHorizontalDrag = ({
  scrollRef,
  loadMorePercentage,
  page,
  setPage,
  scrollbarHeight = 10
}) => {
  const [isScrolling, setIsScrolling] = useState(false);
  const [isDragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [currentX, setCurrentX] = useState(0);
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const calculateScrollDistance = useCallback(() => {
    if (!scrollRef.current) return;
    const element = scrollRef.current;
    const totalWidth = element.scrollWidth - element.clientWidth;
    const currentScroll = element.scrollLeft;
    const scrolledPercentage = (currentScroll / totalWidth) * 100;
    setScrollPercentage(scrolledPercentage);

    if (scrolledPercentage > loadMorePercentage) {
      setPage(page + 1);
    }
  }, [loadMorePercentage, page, scrollRef, setPage]);

  useEffect(() => {
    if (isDragging) {
      const handleMouseMove = e => {
        const { clientX } = e;
        setCurrentX(clientX);
      };

      const handleMouseUp = () => {
        setDragging(false);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);

      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [isDragging, isScrolling]);

  useEffect(() => {
    if (!scrollRef.current) return;

    const scrollContainer = scrollRef.current;
    scrollContainer.addEventListener('scroll', calculateScrollDistance);

    return () =>
      scrollContainer.removeEventListener('scroll', calculateScrollDistance);
  }, [calculateScrollDistance, scrollRef]);

  useEffect(() => {
    if (isDragging) {
      const diff = currentX - startX;
      if (scrollRef?.current) {
        scrollRef.current.scrollLeft = scrollRef.current.scrollLeft - diff;
      }
      setStartX(currentX);
    }
  }, [
    calculateScrollDistance,
    currentX,
    isDragging,
    isScrolling,
    scrollRef,
    startX
  ]);

  const handleMouseDown = e => {
    const { clientX, clientY, target } = e;
    const { bottom } = target.getBoundingClientRect();
    const scrolling =
      bottom - clientY < scrollbarHeight && bottom - clientY > 0;
    setIsScrolling(scrolling);
    if (scrolling) return;

    setDragging(true);
    setStartX(clientX);
  };

  return { handleMouseDown, scrollRef, isDragging, scrollPercentage };
};

export default useHorizontalDrag;
