import { Api, useApiQuery } from '@/api';
import {
  Header,
  Loading,
  Page,
  Avatar,
  DataTable,
  Tooltip
} from '@/components/common';
import { useSelector } from 'react-redux';
import HeaderControls from '@/components/management/HeaderControls';
import {
  HeaderCell,
  JauntyCell,
  MotionCell,
  MotionDataTable
} from '@/pages/studentPredictions/MotionDataTable';
import { useState } from 'react';
import pluralizeString from '@/helpers/pluralizeString';
import { MasteredIcon } from '@/assets/icons';
import { Navigate } from 'react-router-dom';

const ReportsProficiency = () => {
  const schoolId = useSelector(s => s.school.schoolId);
  const groupId = useSelector(s => s.group.groupId);
  const [search, setSearch] = useState('');
  const [tab, setTab] = useState('alphabetical');

  const { data, loading } = useApiQuery(async () => {
    return await Api.getProficiencyReportByGroupId({
      groupId
    });
  }, [groupId]);

  if (loading) return <Loading />;

  if (!groupId) return <Navigate to={`/${schoolId}/reports`} />;

  const constructs = data.constructs || [];
  const students = data.students || [];

  const classAverages = {
    fullName: 'Class average',
    constructProficiencies: constructs,
    proficiency: data.proficiency
  };

  const filteredStudents = students
    .filter(student =>
      student.fullName.toLowerCase().includes(search.toLowerCase())
    )
    .sort((a, b) => {
      if (tab === 'highest')
        return (
          b.proficiency.averageConstructsMastered -
          a.proficiency.averageConstructsMastered
        );
      if (tab === 'lowest')
        return (
          a.proficiency.averageConstructsMastered -
          b.proficiency.averageConstructsMastered
        );
      return a.fullName.localeCompare(b.fullName);
    });

  filteredStudents.unshift(classAverages);

  const columns = [
    [
      <HeaderCell key="summary" style={{ height: '300px' }}>
        <p>
          {data.code} has been set assignments which cover{' '}
          {data.proficiency.constructsAssignedCount}{' '}
          {pluralizeString('skill', data.proficiency.constructsAssignedCount)}.
          This table shows the proficiency of students in each construct.
        </p>
      </HeaderCell>,
      ...filteredStudents.map(student => (
        <HeaderCell key={student.userId}>
          <Avatar
            userId={student.userId}
            name={student.fullName}
            width="2"
            className="mr-2"
          />
          <span>{student.fullName}</span>
        </HeaderCell>
      ))
    ],
    [
      <JauntyCell
        key="total-skills-mastered"
        style={{
          height: '300px'
        }}>
        <strong>Total Skills Mastered</strong>
      </JauntyCell>,
      ...filteredStudents.map(student => (
        <MotionCell key={`${student.userId}-total-skills-mastered`}>
          {student.proficiency.constructsMasteredCount}/
          {data.proficiency.constructsAssignedCount}
        </MotionCell>
      ))
    ],
    ...constructs.map(construct => [
      <>
        <JauntyCell
          key={construct.constructId}
          style={{
            height: '300px'
          }}>
          <span id={`construct-${construct.constructId}`}>
            {construct.construct}
          </span>
          <Tooltip target={`construct-${construct.constructId}`}>
            {construct.construct}
          </Tooltip>
        </JauntyCell>
      </>,
      ...filteredStudents.map(student => {
        const studentConstruct = student.constructProficiencies.find(
          c => c.constructId === construct.constructId
        );

        const attempted = !!studentConstruct.constructAttemptsCount;
        const isMastered = studentConstruct
          ? studentConstruct.isMastered
          : false;

        return (
          <MotionCell
            key={`${student.userId}-${construct.constructId}`}
            style={{
              color: isMastered
                ? 'var(--white)'
                : attempted
                ? 'var(--purple)'
                : 'var(--gray-light)',
              backgroundColor: isMastered
                ? 'var(--purple-light)'
                : attempted
                ? 'var(--purple-lighter)'
                : 'var(--white)'
            }}>
            <strong className="text-uppercase text-sm">
              {isMastered ? (
                <MasteredIcon size={28} />
              ) : attempted ? (
                'Started'
              ) : (
                '-'
              )}
            </strong>
          </MotionCell>
        );
      })
    ])
  ];

  return (
    <>
      <Header>
        <Header.Title>
          <h1>Proficiency Score</h1>
        </Header.Title>
      </Header>
      <Page.Body>
        <DataTable.Card>
          <HeaderControls
            onSearchChange={setSearch}
            searchPlaceHolder="Search for a student"
            onTabChange={setTab}
            tabOptions={[
              { id: 'alphabetical', value: 'Alphabetical' },
              { id: 'highest', value: 'Highest Proficiency' },
              { id: 'lowest', value: 'Lowest Proficiency' }
            ]}
            tabValue={tab}
          />
          <MotionDataTable
            columns={columns}
            loading={loading}
            emptyStateText="No proficiency scores available"
            loadingText="Loading proficiency scores..."
            isHeaderRow={true}
          />
        </DataTable.Card>
      </Page.Body>
    </>
  );
};

export default ReportsProficiency;
