import React, { lazy } from 'react';
import LoadingPage from '@/layouts/LoadingPage';
import { useSelector } from 'react-redux';
import { Navigate, Routes, Route } from 'react-router-dom';
import { FAMILY_URL } from '@/config/settings';
import Motivate from '@/components/Motivate';
import MotivatePage from '@/pages/MotivatePage';
import useTrackPageChange from '@/hooks/useTrackPageChange';
import TopicFlowPage from '@/components/flows/TopicFlowPage';
import AssistantFlowPage from '@/components/flows/AssistantFlowPage';
import { FlowTypeToSlug } from '@/components/flows/helpers/flowItemHelpers';
import Onboarding from '@/components/onboarding/Onboarding';
import OnboardingIntro from '@/components/onboarding/OnboardingIntro';
import OnboardingQuestion from '@/components/onboarding/OnboardingQuestion';
import Schools from '@/components/school/Schools';
import SchoolSearch from '@/components/school/SchoolSearch';
import SchoolAddForm from '@/components/school/SchoolAddForm';
import SchoolJoin from '@/components/school/SchoolJoin';
import JoinClass from '@/components/joinClass/JoinClass';
import Home from '@/components/Home';
import SignupLayout from '@/layouts/SignupLayout';
import GroupLeague from '@/pages/leagues/GroupLeague';
import Dashboard from '@/components/Dashboard';
import ClassDashboard from '@/components/ClassDashboard';
import AssignmentSettings from '@/components/settings/AssignmentSettings';
import ExternalQuizPage from '@/components/b2bPages/ExternalQuizPage';
import Reports from '@/pages/Reports';
import ReportsOverview from '@/components/reports/ReportsOverview';
import ReportsEffort from '@/components/reports/ReportsEffort';
import ReportsProficiency from '@/components/reports/ReportsProficiency';
import ReportsClassesOverview from '@/components/reports/ReportsClassesOverview';

const GrowthPage = lazy(() => import('@/pages/reports/GrowthPage'));
const SchoolReportsPage = lazy(() =>
  import('@/pages/reports/SchoolReportsPage')
);
const SchoolLeague = lazy(() => import('@/pages/leagues/SchoolLeague'));
const InviteCode = lazy(() => import('@/components/auth/InviteCode'));
const Unsubscribe = lazy(() => import('@/components/unsubscribe/Unsubscribe'));
const LoginRedirect = lazy(() => import('@/components/auth/LoginRedirect'));
const Login = lazy(() => import('@/components/auth/Login'));
const ForgotPassword = lazy(() => import('@/components/auth/ForgotPassword'));
const ResetPassword = lazy(() => import('@/components/auth/ResetPassword'));
const UserRolePicker = lazy(() => import('@/components/auth/UserRolePicker'));
const SignUp = lazy(() => import('@/components/auth/SignUp'));
const ConfirmCredentials = lazy(() =>
  import('@/components/auth/ConfirmCredentials')
);
const Logout = lazy(() => import('@/components/auth/Logout'));
const Plan = lazy(() => import('@/pages/Plan'));
const Check = lazy(() => import('@/pages/Check'));
const CheckAssignmentResults = lazy(() =>
  import('@/components/check/AssignmentResults')
);
const CheckStudentResults = lazy(() =>
  import('@/components/check/StudentResults')
);
const Fix = lazy(() => import('@/pages/Fix'));
const Students = lazy(() => import('@/pages/Students'));
const StudentsList = lazy(() => import('@/components/students/StudentsList'));

const StudentManagementContainer = lazy(() =>
  import('@/components/management/students/StudentManagementContainer')
);
const AddStudentsToClasses = lazy(() =>
  import('@/components/management/students/AddStudentsToClassesModal')
);
const ManageStudentClasses = lazy(() =>
  import('@/components/management/students/ManageStudentClassesModal')
);
const ClassLeague = lazy(() => import('@/pages/students/ClassLeague'));
const Student = lazy(() => import('@/pages/Student'));
const StudentOverview = lazy(() =>
  import('@/components/students/StudentOverview')
);
const StudentTopicsList = lazy(() =>
  import('@/components/students/StudentTopicsList')
);
const StudentProfile = lazy(() =>
  import('@/components/students/StudentProfile')
);
const Settings = lazy(() => import('@/pages/Settings'));
const Classes = lazy(() => import('@/components/classes/Classes'));
const TeacherManagementContainer = lazy(() =>
  import('@/components/management/teachers/TeacherManagementContainer')
);
const AddTeachersToClassesModal = lazy(() =>
  import('@/components/management/teachers/AddTeachersToClassesModal')
);
const ManageTeacherClassesModal = lazy(() =>
  import('@/components/management/teachers/ManageTeacherClassesModal')
);
const EditClass = lazy(() => import('@/components/classes/EditClass'));
const Account = lazy(() => import('@/pages/Account'));
const ClassNotifications = lazy(() =>
  import('@/components/classes/ClassNotifications')
);
const TeacherUploads = lazy(() =>
  import('@/components/teacher/TeacherUploads')
);
const Notifications = lazy(() =>
  import('@/components/notifications/Notifications')
);
const ConfigManagement = lazy(() =>
  import('@/components/configmanagement/ConfigManagement')
);
const TeacherProfile = lazy(() =>
  import('@/components/profile/TeacherProfile')
);
const TeacherPending = lazy(() =>
  import('@/components/teacher/TeacherPending')
);
const JoinClassContainer = lazy(() =>
  import('@/components/classes/JoinClassContainer')
);
const ModalLayout = lazy(() => import('@/layouts/ModalLayout'));
const EditClassTeachersContainer = lazy(() =>
  import('@/components/classes/EditClassTeachersContainer')
);
const EditClassStudentsContainer = lazy(() =>
  import('@/components/classes/EditClassStudentsContainer')
);
const MoveStudents = lazy(() => import('@/components/teacher/MoveStudents'));
const StyleGuide = lazy(() => import('@/components/StyleGuide'));
const Layout = lazy(() => import('@/layouts/Layout'));
const SignupRoute = lazy(() => import('./SignupRoute'));
const PrivateRoute = lazy(() => import('./PrivateRoute'));
const PageNotFound = lazy(() => import('@/components/errors/PageNotFound'));
const Switch = lazy(() => import('@/components/classes/Switch'));
const ParentsPage = lazy(() =>
  import('@/components/classes/Parents/ParentsPage')
);
const GroupReportsPage = lazy(() => import('@/pages/reports/GroupReportsPage'));
const StudentPredictionsPage = lazy(() =>
  import('@/pages/studentPredictions/StudentPredictionsPage')
);
const SyncPage = lazy(() => import('@/pages/sync/SyncPage'));
const OAuthLoginPage = lazy(() => import('@/components/auth/OAuthLoginPage'));
const QuizList = lazy(() => import('@/components/quizzes/QuizList'));
const QuizView = lazy(() => import('@/components/quizzes/QuizView'));

const TeacherIndependentTopicResults = lazy(() =>
  import('@/components/results/IndependentStudy/TeacherIndependentTopicResults')
);

// b2b routes
const B2BQuizView = lazy(() => import('@/components/b2bPages/QuizView'));
const B2BQuizList = lazy(() => import('@/components/b2bPages/QuizList'));

const AppRoutes = () => {
  useTrackPageChange();
  const schoolId = useSelector(s => s.school.schoolId);

  // Redirect paths.
  const relativePath = window.location.pathname + window.location.search;
  const redirectRelativePath = relativePath.replace(/^\/+/, ''); // Remove any leading '/'

  return (
    <React.Suspense fallback={<LoadingPage />}>
      <Routes>
        {/* Redirect any old route */}
        <Route path="/c" element={<Navigate to="/" replace />} />
        <Route
          path="/signup/new/teacher"
          element={<Navigate to="/signup/teacher" replace />}
        />
        <Route
          path="/signup/new/student"
          element={<Navigate to="/signup/student" replace />}
        />
        <Route
          path="/signup/teacher/choice"
          element={<Navigate to="/signup/teacher" replace />}
        />
        <Route
          path="/:schoolId/collections/:collectionId?"
          element={
            <Navigate to={`/${schoolId}?modal=%2Fset-work%2Ftopics`} replace />
          }
        />
        <Route
          path="/:schoolId/teachers"
          element={<Navigate to={`/${schoolId}/settings/teachers`} replace />}
        />
        <Route
          path="/:schoolId/classes"
          element={<Navigate to={`/${schoolId}/settings`} replace />}
        />
        <Route path="/:schoolId/analytics/baseline" element={<Navigate to={`/${schoolId}/reports/baseline`} replace />} />
        <Route path="/:schoolId/analytics/growth" element={<Navigate to={`/${schoolId}/reports/groth`} replace />} />
        <Route path="/:schoolId/analytics/school" element={<Navigate to={`/${schoolId}/reports/school`} replace />} />
        <Route path="/:schoolId/analytics/group" element={<Navigate to={`/${schoolId}/reports/group`} replace />} />
        <Route path="/:schoolId/analytics/*" element={<Navigate to={`/${schoolId}/reports`} replace />} />

        {/* Redirect family routes to Eedi Family (including the family school IDs) */}
        <Route
          path="/tutor"
          element={
            <Navigate to={`${FAMILY_URL}/${redirectRelativePath}`} replace />
          }
        />

        <Route path="/" index element={<Home />} />

        <Route path="/unsubscribe" element={<Unsubscribe />} />
        <Route path="/loginredirect" element={<LoginRedirect />} />

        <Route element={<SignupRoute />}>
          <Route element={<SignupLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/login/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/login/reset-password/:resetCode"
              element={<ResetPassword />}
            />
            <Route path="/signup" element={<UserRolePicker />} />
            <Route path="/signup/teacher" element={<SignUp />} />
            <Route path="/signup/:userRole" element={<InviteCode />} />
            <Route
              path="/signup/:userRole/invite-code"
              element={<InviteCode />}
            />
            <Route path="/signup/:userRole/new" element={<SignUp />} />

            <Route path="/signup/confirm" element={<ConfirmCredentials />} />
          </Route>
        </Route>

        <Route
          path="/flow-template/:templateSlug/:flowType/:topicPathwayQuizId?/:questionSequence?"
          element={<TopicFlowPage />}
        />

        <Route
          path="/topic-session/:flowGeneratorSessionId"
          element={<TopicFlowPage />}
        />

        <Route element={<PrivateRoute />}>
          <Route
            element={
              <ModalLayout>
                <Layout />
              </ModalLayout>
            }>
            <Route path="/assistant" element={<AssistantFlowPage />} />
            <Route
              path="/topic/dr/:topicPathwayQuizId/:flowGeneratorSessionId?"
              element={<TopicFlowPage templateSlug={FlowTypeToSlug.DR} />}
            />
            <Route
              path="/topic/d/:topicPathwayQuizId/:flowGeneratorSessionId?"
              element={<TopicFlowPage templateSlug={FlowTypeToSlug.D} />}
            />
            <Route
              path="/quiz-session/:quizSessionId"
              element={<TopicFlowPage />}
            />

            <Route path="/:schoolId" element={<Dashboard />} />
            <Route path="/:schoolId/:groupId" element={<ClassDashboard />} />
            <Route path="/:schoolId/plan" element={<Plan />} />
            <Route path="/:schoolId/check/:weekNumber?" element={<Check />} />
            <Route
              path="/:schoolId/check/:weekNumber/assignments/:assignmentId"
              element={<CheckAssignmentResults />}
            />
            <Route
              path="/:schoolId/assignments/:assignmentId"
              element={<CheckAssignmentResults />}
            />
            <Route
              path="/:schoolId/check/:weekNumber/students/:studentId"
              element={<CheckStudentResults />}
            />

            <Route path="/:schoolId/fix/:weekNumber?" element={<Fix />} />

            <Route
              path="/:schoolId/independent-study/:quizId/:tab?/:questionSequence?"
              element={<TeacherIndependentTopicResults />}
            />

            {/* STUDENTS */}
            <Route path="/:schoolId/students" element={<Students />}>
              <Route path="" element={<StudentsList />} />
            </Route>

            <Route path="/:schoolId/motivate" element={<MotivatePage />}>
              <Route path="" element={<Motivate />} />
              <Route path="class-league" element={<ClassLeague />} />
              <Route path="school-league" element={<SchoolLeague />} />
              <Route path="group-league" element={<GroupLeague />} />
            </Route>

            <Route
              path="/:schoolId/students/:studentId/*"
              element={<Student />}>
              <Route path="" element={<StudentOverview />} />
              <Route path="topics" element={<StudentTopicsList />} />
              <Route path="profile" element={<StudentProfile />} />
            </Route>

            <Route path="/:schoolId/reports" element={<Reports />}>
              <Route path="" element={<ReportsOverview />} />
              <Route path="classes" element={<ReportsClassesOverview />} />
              <Route path="effort" element={<ReportsEffort />} />
              <Route path="proficiency" element={<ReportsProficiency />} />
              <Route path="group" element={<GroupReportsPage />} />
              <Route path="growth" element={<GrowthPage />} />
              <Route path="school" element={<SchoolReportsPage />} />
              <Route path="baseline" element={<StudentPredictionsPage />} />
            </Route>

            <Route path="/:schoolId/settings" element={<Settings />}>
              {['', 'classes/:groupId'].map(path => (
                <Route key={path} path={path} index element={<EditClass />} />
              ))}
              <Route path="assignments" element={<AssignmentSettings />} />
              {['students', ':scope/students'].map(path => (
                <Route
                  path={path}
                  key={path}
                  element={<StudentManagementContainer />}>
                  <Route
                    path="add-classes"
                    element={<AddStudentsToClasses />}
                  />
                  <Route
                    path="manage-classes"
                    element={<ManageStudentClasses />}
                  />
                </Route>
              ))}

              {['teachers', ':scope/teachers'].map(path => (
                <Route
                  path={path}
                  key={path}
                  element={<TeacherManagementContainer />}>
                  <Route
                    path="add-classes"
                    element={<AddTeachersToClassesModal />}
                  />
                  <Route
                    path="manage-classes"
                    element={<ManageTeacherClassesModal />}
                  />
                </Route>
              ))}

              <Route path="parents" element={<ParentsPage />} />
              <Route path="classes" element={<Classes />} />
              <Route path="sync" element={<SyncPage />} />
            </Route>

            {/* SET WORK */}
            <Route
              path="/:schoolId/classes/notifications"
              element={<ClassNotifications />}
            />

            <Route path="/:schoolId/uploads" element={<TeacherUploads />} />

            <Route
              path="/:schoolId/notifications"
              element={<Notifications />}
            />

            <Route path="/config" element={<ConfigManagement />} />

            <Route
              path="/:schoolId/teachers/:id"
              element={<TeacherProfile />}
            />
          </Route>

          <Route path="/:schoolId/pending" element={<TeacherPending />} />

          <Route path={'/quizzes'} element={<QuizList />} />
          <Route path="/quizzes/:shortCode" element={<QuizView />} />

          <Route path="/:schoolId/quizzes/:shortCode?" element={<QuizList />} />

          <Route path="/welcome" element={<Onboarding />}>
            <Route path="" element={<OnboardingIntro />} />
            <Route path="question" element={<OnboardingQuestion />} />
          </Route>

          <Route path="/schools" element={<Schools />}>
            <Route path="" element={<SchoolSearch />} />
            <Route path="add" element={<SchoolAddForm />} />
            <Route path="join" element={<SchoolJoin />} />
          </Route>

          <Route
            path="/:schoolId/classes/add/*"
            element={
              <ModalLayout>
                <JoinClassContainer />
              </ModalLayout>
            }
          />

          <Route path="/:schoolId/classes/new/*" element={<JoinClass />} />

          <Route
            path="/:schoolId/settings/classes/:groupId/teachers/edit"
            element={<EditClassTeachersContainer />}
          />
          <Route
            path="/:schoolId/classes/:groupId/students/edit"
            element={<EditClassStudentsContainer />}
          />
          <Route
            path="/:schoolId/classes/:groupId/students/move"
            element={<MoveStudents />}
          />
          <Route
            path="/:schoolId/classes/:groupId/:path?"
            element={<Switch />}
          />
          <Route
            path="/:schoolId/classes/:groupId/switch"
            element={<Switch />}
          />
        </Route>
        <Route path="/ext" element={<ExternalQuizPage />}>
          <Route path="quizzes" element={<B2BQuizList />} />
          <Route path="quizzes/:shortCode" element={<B2BQuizView />} />
        </Route>
        <Route path="/oauth-login" element={<OAuthLoginPage />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/account" element={<Account />} />
        <Route path="/style-guide" element={<StyleGuide />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </React.Suspense>
  );
};

export default AppRoutes;
