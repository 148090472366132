import { Api, useApiQuery } from '@/api';
import {
  Col,
  Header,
  Loading,
  Page,
  Row,
  Card,
  DataTable,
  Avatar,
  ProgressBar
} from '@/components/common';
import { useSelector } from 'react-redux';
import pluralizeString from '@/helpers/pluralizeString';
import HeaderControls from '@/components/management/HeaderControls';
import styled from 'styled-components';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

const initialSortBy = [
  {
    id: 'fullName',
    desc: false
  }
];

const ReportsOverview = () => {
  const groupId = useSelector(s => s.group.groupId);
  const schoolId = useSelector(s => s.school.schoolId);
  const [selectedReportGroup, setSelectedReportGroup] = useState(null);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState(initialSortBy);

  const { data, loading } = useApiQuery(async () => {
    const report = await Api.getEffortAndProficiencyReportByGroupId({
      groupId
    });

    const lowCompletionStudents = report.students.filter(student => {
      return (
        student.completion.completionAverage < report.completion.averageCompletion
      );
    });

    const highEffortStudents = report.students.filter(student => {
      return (
        student.effort.averageEffortScore > report.effort.averageEffortScore
      );
    });

    const lowProficiencyStudents = report.students.filter(student => {
      return (
        student.proficiency.averageConstructsMastered <
        report.proficiency.averageConstructsMastered
      );
    });

    return {
      report,
      lowCompletionStudents,
      highEffortStudents,
      lowProficiencyStudents
    };
  }, [groupId]);

  if (!groupId) {
    return <Navigate to={`/${schoolId}/reports/classes`} />;
  }

  if (loading) return <Loading />;

  const columns = [
    {
      Header: 'Student',
      accessor: 'student',
      Cell: ({ row }) => (
        <div className="d-flex align-items-center">
          <Avatar
            userId={row.original.userId}
            name={row.original.fullName}
            width="2"
            className="mr-2"
          />
          <span>{row.original.fullName}</span>
        </div>
      ),
      sortType: (a, b) => a.fullName.localeCompare(b.fullName)
    },
    {
      Header: 'Completion (Avg)',
      accessor: 'completion',
      Cell: ({ row }) => (
        <div className="d-flex align-items-center">
          <span className="mr-2">{`${Math.round(
            row.original.completion.completionAverage * 100
          )}%`}</span>
          <ProgressBar
            currentIndex={row.original.completion.completionAverage * 100}
            color="primary"
            style={{
              width: '100px'
            }}
          />
        </div>
      ),
      sortType: (a, b) =>
        a.completion.completionAverage -
        b.completion.completionAverage
    },
    {
      Header: 'Effort',
      accessor: 'effort',
      Cell: ({ row }) => (
        <div className="d-flex align-items-center">
          <span className="mr-2">{`${Math.round(
            row.original.effort.averageEffortScore * 100
          )}%`}</span>
          <ProgressBar
            currentIndex={row.original.effort.averageEffortScore * 100}
            color="green"
            style={{ width: '100px' }}
          />
        </div>
      ),
      sortType: (a, b) =>
        a.effort.averageEffortScore -
        b.effort.averageEffortScore
    },
    {
      Header: 'Skills Mastered',
      accessor: 'proficiency',
      Cell: ({ row }) => (
        <div className="d-flex align-items-center">
          <span className="mr-2">{`${row.original.proficiency.constructsMasteredCount}/${data.report.proficiency.constructsAssignedCount}`}</span>
          <ProgressBar
            currentIndex={row.original.proficiency.constructsMasteredCount}
            total={data.report.proficiency.constructsAssignedCount}
            color="purple-light"
            style={{ width: '100px' }}
          />
        </div>
      ),
      sortType: (a, b) =>
        a.proficiency.constructsMasteredCount -
        b.proficiency.constructsMasteredCount
    }
  ];

  const students = selectedReportGroup
    ? data[`${selectedReportGroup}Students`]
    : data.report.students;

  const filteredData = students.filter(student =>
    student.fullName.toLowerCase().includes(search.toLowerCase())
  );

  const column = columns.find(col => col.accessor === sortBy[0].id);
  const sortedData = filteredData.sort((a, b) => {
    if (column && column.sortType) {
      return sortBy[0].desc ? column.sortType(b, a) : column.sortType(a, b);
    }
  });

  const classAverage = {
    fullName: 'Class Average',
    completion: {
      completionAverage: data.report.completion.completionAverage
    },
    effort: {
      averageEffortScore: data.report.effort.averageEffortScore
    },
    proficiency: {
      constructsMasteredCount: data.report.proficiency.constructsMasteredCount
    }
  };

  sortedData.push(classAverage);

  const selectReportGroup = group => {
    setSelectedReportGroup(selectedReportGroup === group ? null : group);
  };

  const getPagedData = ({ sortBy }) => {
    setSortBy(sortBy);
  };

  return (
    <>
      <Header>
        <Header.Title>
          <div>
            <h1>Overview</h1>
            <h2 className="subtitle">
              {data.report.code} has been set{' '}
              {data.report.effort.assignmentsCount} quizzes covering{' '}
              {data.report.proficiency.constructsAssignedCount} skills
            </h2>
          </div>
        </Header.Title>
      </Header>
      <Page.Body>
        <Row className="mb-4">
          {data.lowCompletionStudents.length > 0 && (
            <Col md="4">
              <StyledCard
                active={selectedReportGroup === 'lowCompletion'}
                onClick={() => selectReportGroup('lowCompletion')}>
                <Card.Body>
                  <h3>Chase these students</h3>
                  <p>
                    {data.lowCompletionStudents.length}{' '}
                    {pluralizeString('student', data.lowCompletionStudents.length)}{' '}
                    {data.lowCompletionStudents.length === 1 ? 'needs' : 'need'} to
                    try harder
                  </p>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="d-flex mr-3">
                      {data.lowCompletionStudents.slice(0, 3).map(student => (
                        <Avatar
                          userId={student.userId}
                          name={student.fullName}
                          width="2"
                          style={{ marginRight: '-0.5rem' }}
                        />
                      ))}
                    </div>
                    <span>
                      View {data.lowCompletionStudents.length}{' '}
                      {pluralizeString(
                        'student',
                        data.lowCompletionStudents.length
                      )}
                    </span>
                  </div>
                </Card.Body>
              </StyledCard>
            </Col>
          )}
          {data.highEffortStudents.length > 0 && (
            <Col md="4">
              <StyledCard
                active={selectedReportGroup === 'highEffort'}
                onClick={() => selectReportGroup('highEffort')}>
                <Card.Body>
                  <h3>Praise these students</h3>
                  <p>
                    {data.highEffortStudents.length}{' '}
                    {pluralizeString('student', data.highEffortStudents.length)}{' '}
                    {data.highEffortStudents.length === 1 ? 'is' : 'are'}{' '}
                    working hard
                  </p>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="d-flex mr-3">
                      {data.highEffortStudents.slice(0, 3).map(student => (
                        <Avatar
                          userId={student.userId}
                          name={student.fullName}
                          width="2"
                          style={{ marginRight: '-0.5rem' }}
                        />
                      ))}
                    </div>
                    <span>
                      View {data.highEffortStudents.length}{' '}
                      {pluralizeString(
                        'student',
                        data.highEffortStudents.length
                      )}
                    </span>
                  </div>
                </Card.Body>
              </StyledCard>
            </Col>
          )}
          {data.lowProficiencyStudents.length > 0 && (
            <Col md="4">
              <StyledCard
                active={selectedReportGroup === 'lowProficiency'}
                onClick={() => selectReportGroup('lowProficiency')}>
                <Card.Body>
                  <h3>Support these students</h3>
                  <p>
                    {data.lowProficiencyStudents.length}{' '}
                    {pluralizeString(
                      'student',
                      data.lowProficiencyStudents.length
                    )}{' '}
                    {data.lowProficiencyStudents.length === 1 ? 'has' : 'have'}{' '}
                    low proficiency scores
                  </p>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="d-flex mr-3">
                      {data.lowProficiencyStudents.slice(0, 3).map(student => (
                        <Avatar
                          userId={student.userId}
                          name={student.fullName}
                          width="2"
                          style={{ marginRight: '-0.5rem' }}
                        />
                      ))}
                    </div>
                    <span>
                      View {data.lowProficiencyStudents.length}{' '}
                      {pluralizeString(
                        'student',
                        data.lowProficiencyStudents.length
                      )}
                    </span>
                  </div>
                </Card.Body>
              </StyledCard>
            </Col>
          )}
        </Row>

        <DataTable.Card>
          <DataTable
            columns={columns}
            data={sortedData}
            sortByOptions={initialSortBy}
            fetchDataPage={getPagedData}
            pageSize={sortedData.length}
            enablePagination={false}
            headerContent={
              <HeaderControls
                onSearchChange={setSearch}
                searchPlaceHolder="Search for a student"
                tabValue={selectedReportGroup}
                onTabChange={selectReportGroup}
              />
            }
          />
        </DataTable.Card>
      </Page.Body>
    </>
  );
};

export default ReportsOverview;

const StyledCard = styled(Card)`
  border: 0.25rem solid transparent;
  background-color: var(--blue-lightest);
  cursor: pointer;
  ${props => props.active && 'border-color: var(--indigo);'}
`;
