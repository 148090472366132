import useCsvDownload from '@/hooks/useCsvDownload';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const updateBotContext = ({
  context = '',
  contextSummary = '',
  piiProperties = [],
  promptSuggestions = [],
  dataPromptSuggestions = [],
  contextUrl = ''
}) => ({
  type: 'UPDATE_BOT_CONTEXT',
  context,
  contextSummary,
  piiProperties,
  promptSuggestions,
  dataPromptSuggestions,
  contextUrl
});

export const useUpdateBotContext = () => {
  const { generateCsv } = useCsvDownload({});
  const dispatch = useDispatch();

  const setBotContext = useCallback(
    ({
      context = '',
      contextSummary = '',
      piiProperties = [],
      promptSuggestions = [],
      dataPromptSuggestions = [],
      contextUrl = '',
      data = null
    }) => {
      try {
        let dataContext =
          !context?.length && data?.length > 0
            ? `### Context Data ###\n${generateCsv({
                data,
                failSilently: true
              })}`
            : null;

        context = context?.length ? context : dataContext;

        dispatch(
          updateBotContext({
            context,
            contextSummary,
            piiProperties,
            promptSuggestions,
            dataPromptSuggestions,
            contextUrl
          })
        );
      } catch (e) {
        console.error(e);
      }
    },
    [dispatch, generateCsv]
  );

  return { setBotContext };
};
