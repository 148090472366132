import * as React from 'react';
const SvgMastered = ({ size = '16', color, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fill={color ?? 'currentColor'}
      fillRule="evenodd"
      d="M10.651 2.558a1.91 1.91 0 0 1 2.575-.11l.123.11L15.16 4.37h2.561a1.91 1.91 0 0 1 1.902 1.764l.006.143V8.84l1.812 1.812a1.907 1.907 0 0 1 .11 2.576l-.111.121-1.812 1.812v2.562a1.907 1.907 0 0 1-1.765 1.902l-.142.005h-2.56l-1.813 1.812a1.907 1.907 0 0 1-2.575.111l-.122-.11-1.812-1.813H6.278a1.91 1.91 0 0 1-1.903-1.764l-.005-.143V15.16l-1.812-1.812a1.907 1.907 0 0 1-.11-2.576l.11-.121L4.37 8.839V6.277a1.907 1.907 0 0 1 1.765-1.901l.143-.006h2.561zm4.286 6.564-4.048 4.048-1.686-1.686a.954.954 0 0 0-1.349 1.35l2.293 2.292a1.05 1.05 0 0 0 1.484 0l4.654-4.655a.953.953 0 0 0-1.348-1.349"
      clipRule="evenodd"></path>
  </svg>
);

export default SvgMastered;
