import { Api, useApiQuery } from '@/api';
import {
  Header,
  Loading,
  Page,
  DataTable,
  Avatar,
  ProgressBar
} from '@/components/common';
import { useSelector } from 'react-redux';
import HeaderControls from '@/components/management/HeaderControls';
import { useState } from 'react';
import { Navigate } from 'react-router';

const ReportsClassesOverview = () => {
  const groups = useSelector(s => s.school.groups);
  const groupId = useSelector(s => s.group.groupId);
  const schoolId = useSelector(s => s.school.schoolId);
  const [search, setSearch] = useState('');

  const { data, loading } = useApiQuery(async () => {
    const groupIds = groups.map(group => group.groupId);
    return await Api.getEffortAndProficiencyReport({
      groupIds
    });
  }, [groups]);

  if (groupId) {
    return <Navigate to={`/${schoolId}/reports`} />;
  }

  if (loading) return <Loading />;

  const columns = [
    {
      Header: 'Class',
      accessor: 'class',
      Cell: ({ row }) => (
        <div className="d-flex align-items-center">
          <Avatar name={row.original.code} width="2" className="mr-2" />
          <span>{row.original.code}</span>
        </div>
      )
    },
    {
      Header: 'Completion (Avg)',
      accessor: row => (
        <div className="d-flex align-items-center">
          <span className="mr-2">{`${Math.round(
            row.completion.completionAverage * 100
          )}%`}</span>
          <ProgressBar
            currentIndex={row.completion.completionAverage * 100}
            color="primary"
            style={{
              width: '100px'
            }}
          />
        </div>
      )
    },
    {
      Header: 'Effort',
      accessor: row => (
        <div className="d-flex align-items-center">
          <span className="mr-2">{`${Math.round(
            row.effort.averageEffortScore * 100
          )}%`}</span>
          <ProgressBar
            currentIndex={row.effort.averageEffortScore * 100}
            color="green"
            style={{ width: '100px' }}
          />
        </div>
      )
    },
    {
      Header: 'Skills Mastered',
      accessor: row => (
        <div className="d-flex align-items-center">
          <span className="mr-2">{`${row.proficiency.constructsMasteredCount}/${row.proficiency.constructsAssignedCount}`}</span>
          <ProgressBar
            currentIndex={row.proficiency.constructsMasteredCount}
            total={row.proficiency.constructsAssignedCount}
            color="purple-light"
            style={{ width: '100px' }}
          />
        </div>
      )
    }
  ];

  const filteredData = data.filter(group =>
    group.code.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      <Header>
        <Header.Title>
          <div>
            <h1>Overview</h1>
          </div>
        </Header.Title>
      </Header>
      <Page.Body>
        <DataTable.Card>
          <DataTable
            columns={columns}
            data={filteredData}
            headerContent={
              <HeaderControls
                onSearchChange={setSearch}
                searchPlaceHolder="Search for a class"
              />
            }
          />
        </DataTable.Card>
      </Page.Body>
    </>
  );
};

export default ReportsClassesOverview;