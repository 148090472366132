import { toaster } from '@/components/common';
import FileSaver from 'file-saver';
import { Parser } from '@json2csv/plainjs';
import { useCallback, useState, useEffect } from 'react';

const useCsvDownload = ({
  data,
  filename = 'download.csv',
  fields = [],
  fieldNames = []
}) => {
  const [state, setState] = useState({
    data: null,
    filename: null,
    fields: [],
    fieldNames: [],
    csv: null
  });

  const getCsv = useCallback(({ values, fields, fieldNames }) => {
    const formattedFields = fields.map((field, i) => ({
      label: fieldNames[i] || field,
      value: field
    }));

    const parser = new Parser({ fields: formattedFields });
    const props = values.map(value => {
      const row = {};
      fields.forEach(field => {
        row[field] = value[field] || null;
      });
      return row;
    });
    return parser.parse(props);
  }, []);

  const getFields = useCallback(data => {
    const fields = [];

    if (Array.isArray(data)) {
      data.forEach(item => {
        Object.keys(item).forEach(key => {
          if (!fields.includes(key)) fields.push(key);
        });
      });
    } else if (typeof data === 'object') {
      Object.keys(data).forEach(key => {
        fields.push(key);
      });
    }

    return fields;
  }, []);

  const getData = useCallback(data => {
    let fields = [];
    if (Array.isArray(data)) {
      fields = [...data];
    } else if (typeof data === 'object') {
      fields.push(data);
    }
    return fields;
  }, []);

  const downloadCsv = useCallback(
    (fileName, csv) => {
      if (!state.csv && !csv) {
        toaster.danger('No CSV to download!');
        return;
      }
      const filename = fileName || state.filename;
      const blob = new Blob([csv || state.csv], {
        type: 'text/csv;charset=utf-8'
      });
      FileSaver.saveAs(blob, filename);
      return blob;
    },
    [state.csv, state.filename]
  );

  const replacer = () => {
    const visited = new WeakSet();

    return (key, value) => {
      if (key === '') return value;
      if (typeof value === 'object' && value !== null) {
        if (visited.has(value)) return;
        visited.add(value);
      }
      return value;
    };
  };

  const generateCsv = useCallback(
    ({ data, failSilently = false }) => {
      try {
        if (!data) return null;

        data = JSON.parse(JSON.stringify(data, replacer()));
        const csvFields = fields.length ? fields : getFields(data);
        const csvFieldNames = fieldNames.length ? fieldNames : csvFields;
        const mappedData = getData(data);
        const csv = getCsv({
          values: mappedData,
          fields: csvFields,
          fieldNames: csvFieldNames
        });
        setState({
          ...state,
          csv,
          fields: csvFields,
          fieldNames: csvFieldNames,
          data: mappedData,
          filename
        });
        return csv;
      } catch (e) {
        console.error(e);
        !failSilently && toaster.danger('Failed to generate CSV!');
      }
      return null;
    },
    [fieldNames, fields, filename, getCsv, getData, getFields, state]
  );

  useEffect(() => {
    const runEffect = async () => {
      if ((state.data === null || state.filename !== filename) && data) {
        setState({ data, filename });
        generateCsv({ data });
      }
    };
    runEffect();
  }, [data, filename, generateCsv, state.data, state.filename]);

  return { state, download: downloadCsv, generateCsv };
};

export default useCsvDownload;
