import { Filter, SearchInput, Card } from '@/components/common';
import React, { useState, useEffect } from 'react';
import useDebounce from '@/hooks/useDebouncedValue';
import { useUpdateBotContext } from '@/actions/botContext';
import useCsvDownload from '@/hooks/useCsvDownload';
import objectHash from 'object-hash';

export const TabFilter = {
  active: 'active',
  archived: 'archived',
  colleagues: 'colleagues',
  approve: 'approve'
};

export const HeaderControls = ({
  onSearchChange,
  searchPlaceHolder,
  onTabChange,
  tabOptions,
  tabValue,
  actionButtons,
  data,
  context = '',
  contextSummary = '',
  contextUrl = '',
  piiProperties = [],
  promptSuggestions = [],
  dataPromptSuggestions = [],
  isUseDataForContext = true
}) => {
  const { generateCsv } = useCsvDownload({});
  const { setBotContext } = useUpdateBotContext();
  const [search, setSearch] = useState('');
  const debouncedSearchValue = useDebounce(search, 500);
  const [prevDataHash, setPrevDataHash] = useState(null);

  useEffect(() => {
    const runEffect = () => {
      return typeof onSearchChange === 'function'
        ? onSearchChange(debouncedSearchValue)
        : null;
    };
    runEffect();
  }, [debouncedSearchValue, onSearchChange]);

  useEffect(() => {
    const runEffect = () => {
      const dataInput =
        isUseDataForContext && !!data
          ? data?.data?.length
            ? data.data
            : data
          : [];

      const dataHash = objectHash(Array.isArray(dataInput) ? dataInput : []);

      if (dataHash === prevDataHash) return;

      setPrevDataHash(dataHash);
      setBotContext({
        contextSummary,
        contextUrl,
        context:
          Array.isArray(dataInput) && !context?.length
            ? `### Data Context ###\n${generateCsv({
                data: dataInput,
                failSilently: true
              })}`
            : context,
        piiProperties,
        promptSuggestions,
        dataPromptSuggestions
      });
    };
    runEffect();
  }, [
    context,
    contextSummary,
    contextUrl,
    data,
    dataPromptSuggestions,
    generateCsv,
    isUseDataForContext,
    piiProperties,
    prevDataHash,
    promptSuggestions,
    setBotContext
  ]);

  return (
    <Card.Header tint style={{ borderRadius: 0 }}>
      {onSearchChange && (
        <Card.Header.Left>
          <SearchInput
            type="text"
            placeholder={searchPlaceHolder}
            onChange={e => setSearch(e.target.value)}
            onClear={() => setSearch('')}
            value={search}
          />
        </Card.Header.Left>
      )}

      {tabOptions && (
        <Card.Header.Center>
          <Filter
            currentFilter={tabValue}
            filters={tabOptions}
            onClick={onTabChange}
          />
        </Card.Header.Center>
      )}

      {actionButtons && (
        <Card.Header.Right className="d-flex">
          {actionButtons.map((actionButton, i) => (
            <span key={i} className="mx-1">
              {actionButton}
            </span>
          ))}
        </Card.Header.Right>
      )}
    </Card.Header>
  );
};

export default HeaderControls;
