import { createContext, useState, useEffect } from 'react';
import CookieBanner from './CookieBanner';
import Cookies from 'js-cookie';
import { ENVIRONMENT } from '@/config/settings';

export const cookies = [
  {
    name: 'essential',
    label: 'Essential Cookies',
    description:
      'These cookies are necessary for the website to function and cannot be switched off.',
    defaultConsent: true,
    disabled: true
  },
  {
    name: 'functional',
    label: 'Functional Cookies',
    description:
      'These cookies enable the website to provide enhanced functionality and personalisation.',
    defaultConsent: false,
    disabled: false,
    services: ['Google Analytics', 'Hotjar']
  },
  {
    name: 'analytics',
    label: 'Analytics Cookies',
    description:
      'These cookies collect information about how you use the website, such as the pages you visit and the links you click on.',
    defaultConsent: false,
    disabled: false,
    services: ['Google Analytics', 'Hotjar']
  }
];

export const CookieContext = createContext();

export const CookieProvider = ({ children }) => {
  const [cookieConsent, setCookieConsent] = useState(null);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = Cookies.get('cookieConsent');
    setCookieConsent(consent ? JSON.parse(consent) : null);
  }, []);

  const updateConsent = consent => {
    const cookieOptions = { expires: 365 };
    if (ENVIRONMENT === 'prod') {
      cookieOptions.domain = '.eedi.com'; // Prepend a dot to allow all subdomains
    }
    Cookies.set('cookieConsent', JSON.stringify(consent), cookieOptions);
    setCookieConsent(consent);
    setShowBanner(false);
  };

  const openCookieBanner = () => {
    setShowBanner(true);
  };

  return (
    <CookieContext.Provider value={{ cookieConsent, updateConsent, openCookieBanner }}>
      {(!cookieConsent || showBanner) && <CookieBanner />}
      {children}
    </CookieContext.Provider>
  );
};
