import { Api, useApiQuery } from '@/api';
import { Header, Loading, Page, Avatar, DataTable, Tooltip } from '@/components/common';
import { useSelector } from 'react-redux';
import HeaderControls from '@/components/management/HeaderControls';
import {
  HeaderCell,
  JauntyCell,
  MotionCell,
  MotionDataTable
} from '@/pages/studentPredictions/MotionDataTable';
import { useState } from 'react';
import { Navigate } from 'react-router';

const ReportsEffort = () => {
  const schoolId = useSelector(s => s.school.schoolId);
  const groupId = useSelector(s => s.group.groupId);
  const [search, setSearch] = useState('');
  const [tab, setTab] = useState('alphabetical');

  const { data, loading } = useApiQuery(async () => {
    return await Api.getEffortReportByGroupId({
      groupId
    });
  }, [groupId]);

  if (loading) return <Loading />;

  if (!groupId) return <Navigate to={`/${schoolId}/reports`} />;

  const assignments = data.assignments || [];
  const students = data.students || [];

  const classAverages = {
    fullName: 'Class average',
    assignmentEfforts: assignments.map(assignment => ({
      assignmentId: assignment.assignmentId,
      effortScore: assignment.averageEffortScore
    }))
  };

  const filteredStudents = students
    .filter(student =>
      student.fullName.toLowerCase().includes(search.toLowerCase())
    )
    .sort((a, b) => {
      if (tab === 'highest')
        return b.effort.averageEffortScore - a.effort.averageEffortScore;
      if (tab === 'lowest')
        return a.effort.averageEffortScore - b.effort.averageEffortScore;
      return a.fullName.localeCompare(b.fullName); // Default to alphabetical
    });
  filteredStudents.unshift(classAverages);

  const columns = [
    [
      <HeaderCell key="summary" style={{ height: '300px' }}>
        <p>
          Eedi's Effort score takes into account question correctness, response
          times, video lesson engagement and quality of answers.
        </p>
      </HeaderCell>,
      ...filteredStudents.map(student => (
        <HeaderCell key={student.userId}>
          <Avatar
            userId={student.userId}
            name={student.fullName}
            width="2"
            className="mr-2"
          />
          <span>{student.fullName}</span>
        </HeaderCell>
      ))
    ],
    [
      <JauntyCell
        key="average-effort"
        style={{
          height: '300px'
        }}>
        <strong>Average Effort Score</strong>
      </JauntyCell>,
      ...filteredStudents.map(student => {
        const averageEffortScore = data.effort.averageEffortScore;
        const normalizedEffortScore =
          averageEffortScore && averageEffortScore > 0
            ? Math.round(averageEffortScore * 100)
            : null;


        return (
          <MotionCell
            key={`${student.userId}-average-effort`}
            rag={normalizedEffortScore}>
            <strong className="text-uppercase text-sm">
              {normalizedEffortScore >= 60
                ? 'High'
                : normalizedEffortScore >= 50
                ? 'Normal'
                : typeof normalizedEffortScore === 'number' &&
                  !isNaN(normalizedEffortScore)
                ? 'Low'
                : '-'}
            </strong>
          </MotionCell>
        );
      })
    ],
    ...assignments.map(assignment => [
      <JauntyCell
        key={assignment.assignmentId}
        style={{
          height: '300px'
        }}>
        <span id={`assignment-${assignment.assignmentId}`}>
          {assignment.quizName}
        </span>
        <Tooltip target={`assignment-${assignment.assignmentId}`}>
          {assignment.quizName}
        </Tooltip>
      </JauntyCell>,
      ...filteredStudents.map(student => {
        const effort = student.assignmentEfforts.find(
          e => e.assignmentId === assignment.assignmentId
        );
        const effortScore = effort ? effort.effortScore : null;
        const averageEffortScore = data.effort.averageEffortScore;

        const normalizedEffortScore =
          effortScore && effortScore > 0
            ? Math.round((effortScore / averageEffortScore) * 50)
            : null;

        console.log('normalizedEffortScore 555', normalizedEffortScore);

        return (
          <MotionCell
            key={`${student.userId}-${assignment.assignmentId}`}
            rag={normalizedEffortScore}>
            <strong className="text-uppercase text-sm">
              {normalizedEffortScore >= 60
                ? 'High'
                : normalizedEffortScore >= 50
                ? 'Normal'
                : typeof normalizedEffortScore === 'number' &&
                  !isNaN(normalizedEffortScore)
                ? 'Low'
                : '-'}
            </strong>
          </MotionCell>
        );
      })
    ])
  ];

  return (
    <>
      <Header>
        <Header.Title>
          <h1>Effort score</h1>
        </Header.Title>
      </Header>
      <Page.Body>
        <DataTable.Card>
          <HeaderControls
            onSearchChange={setSearch}
            searchPlaceHolder="Search for a student"
            onTabChange={setTab}
            tabOptions={[
              { id: 'alphabetical', value: 'Alphabetical' },
              { id: 'highest', value: 'Highest Effort' },
              { id: 'lowest', value: 'Lowest Effort' }
            ]}
            tabValue={tab}
          />
          <MotionDataTable
            columns={columns}
            loading={loading}
            emptyStateText="No effort scores available"
            loadingText="Loading effort scores..."
            isHeaderRow={true}
          />
        </DataTable.Card>
      </Page.Body>
    </>
  );
};

export default ReportsEffort;
