import { Helmet } from 'react-helmet';
import { Container } from '@/components/common';
import styled from 'styled-components';
import { media } from '@/styles';

const Page = ({ title = '', children }) => (
  <>
    <Helmet>
      <title>{title && title !== '' ? title + ' - Eedi' : 'Eedi'}</title>
    </Helmet>
    <div className="d-flex">{children}</div>
  </>
);

Page.Main = ({ container, children, ...props }) => (
  <Main {...props}>
    {container ? (
      <Container className={`container-${container}`}>{children}</Container>
    ) : (
      <>{children}</>
    )}
  </Main>
);

Page.Sidebar = styled.div.attrs({
  className: 'page-sidebar'
})`
  border-right: 1px solid var(--border-color);
  background-color: #fbfcff;
  padding: 2rem 1.5rem;
  flex: 0 0 14rem;
  width: 14rem;
  min-height: calc(100vh - var(--header-height));
  max-height: 100vh;
  position: sticky;
  top: 0;
  border-radius: var(--border-radius-xl) 0 0 0;
  overflow-y: auto;
  padding-bottom: var(--header-height);

  h3 {
    margin-left: 0.75rem;
    margin-bottom: 1.5rem;
  }

  ${media.xxl`
    flex: 0 0 18rem;
    width: 18rem;
  `};
`;

Page.Body = styled.div`
  padding: 1.25rem;
  ${media.sm`
    padding: 1.75rem;
  `};
`;

Page.Header = styled.div`
  padding: 2rem 1.75rem 0 1.75rem;
  min-height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    margin-bottom: 0;
  }
`;

const Main = styled.div.attrs({
  className: 'page-main'
})`
  flex: 1;
  width: 100%;
  position: relative;
  background-color: var(--white);
  border-radius: var(--border-radius-xl) var(--border-radius-xl) 0 0;
  overflow: clip;
  min-height: calc(100vh - var(--header-height));

  .page-sidebar + & {
    border-radius: 0 var(--border-radius-xl) 0 0;
    width: calc(100% - 14rem);
    ${media.xxl`
      width: calc(100% - 18rem);
    `};
  }
`;

export default Page;
