import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import EmptyState from '@/components/EmptyState';
import React, { forwardRef, useRef, useState } from 'react';
import useHorizontalDrag from './useHorizontalDrag';
import LongLoading from '@/components/common/LongLoading';
import { sizes } from '@/styles';

export const MotionDataTable = ({
  columns = [],
  loading = false,
  loadingText = 'Loading... please wait...',
  emptyStateText = 'No data found',
  pageLength = 30,
  currentPage = 1,
  loadMorePercentage = 95,
  columnWidth = null,
  isHeaderRow = true,
  isScroll = true,
  ...props
}) => {
  const scrollRef = useRef(null);
  const [page, setPage] = useState(currentPage);
  const { handleMouseDown } = useHorizontalDrag({
    scrollRef,
    loadMorePercentage,
    page,
    setPage
  });

  if (!columns.length && !loading) return <EmptyState title={emptyStateText} />;

  if (loading) return <LongLoading loadingText={loadingText} />;

  return (
    <MotionTable
      id="motionTable"
      ref={scrollRef}
      isscroll={isScroll}
      onMouseDown={handleMouseDown}
      {...props}>
      {columns
        //.slice(isHeaderRow ? 1 : 0, pageLength * page)
        .map((c, ix) => {
          if (ix == 0 && isHeaderRow) {
            return (
              <MotionColumn
                style={{
                  borderRight: '1px solid var(--border-color)',
                  position: 'sticky',
                  left: 0,
                  zIndex: 1000
                }}>
                {columns[0].map((cell, i) => (
                  <React.Fragment key={i}>{cell}</React.Fragment>
                ))}
              </MotionColumn>
            );
          }
          return (
            <MotionColumn key={ix} style={{ width: columnWidth }}>
              {c.map((cell, i) => (
                <React.Fragment key={`${i}-${ix}`}>{cell}</React.Fragment>
              ))}
            </MotionColumn>
          );
        })}
    </MotionTable>
  );
};

export const MotionTable = forwardRef((props, ref) => {
  const { children, ...otherProps } = props;
  return (
    <AnimatePresence>
      <TableWrapper
        {...otherProps}
        ref={ref}
        variants={{
          hidden: { opacity: 0 },
          show: {
            opacity: 1,
            transition: {
              staggerChildren: 0.1
            }
          }
        }}
        initial="hidden"
        animate="show">
        {children}
      </TableWrapper>
    </AnimatePresence>
  );
});

export const MotionColumn = ({ children, ...props }) => (
  <AnimatePresence>
    <Column
      {...props}
      variants={{
        hidden: { opacity: 0, scale: 0.6 },
        show: {
          opacity: 1,
          scale: 1,
          transition: {
            staggerChildren: 0.05
          }
        }
      }}>
      {children}
    </Column>
  </AnimatePresence>
);

export const MotionCell = ({ children, ...props }) => (
  <AnimatePresence>
    <Cell
      {...props}
      variants={{
        hidden: { opacity: 0, scale: 0.6 },
        show: {
          opacity: 1,
          scale: 1,
          transition: {
            staggerChildren: 0.1,
            type: 'spring'
          }
        }
      }}>
      {children}
    </Cell>
  </AnimatePresence>
);

export const JauntyCell = ({ children, ...props }) => (
  <JauntyCellWrapper {...props}>
    <JauntyCellInner>{children}</JauntyCellInner>
  </JauntyCellWrapper>
);

export const Column = styled(motion.div)``;

export const Cell = styled(motion.div)`
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  overflow: hidden;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 80px;
  min-height: 60px;
  ${({ rag = null, fill = '#fff' }) => {
    let backgroundColor = fill;
    let color = '';

    if (rag >= 60) {
      backgroundColor = 'var(--green)';
      color = 'var(--white)';
    } else if (rag >= 50) {
      backgroundColor = 'var(--green-light)';
      color = 'var(--green-dark)';
    } else if (typeof rag === 'number' && !isNaN(rag)) {
      backgroundColor = 'var(--red-lighter)';
      color = 'var(--red)';
    }

    return `
      background-color: ${backgroundColor};
      color: ${color};
    `;
  }}
  :hover {
    filter: saturate(2);
  }
`;

export const HeaderCell = styled(MotionCell)`
  width: 350px;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  padding: 0 1rem;
  border-right: none !important;
  border-left: none !important;
`;

const JauntyCellWrapper = styled(Cell)`
  position: relative;
  top: 0px;
  left: 150px;
  height: 200px;
  transform: skew(-45deg, 0deg);
  border-left: 1px solid var(--border-color);
`;

const JauntyCellInner = styled.div`
  transform: skew(45deg, 0deg) rotate(315deg);
  position: absolute;
  text-align: left;
  white-space: nowrap;
  width: 330px;
`;

const TableWrapper = styled(motion.div)`
  background: var(--blue-lightest);
  overflow-x: ${({ isscroll }) => (isscroll ? 'scroll' : 'hidden')};
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  width: 100%;

  /* Enable momentum scrolling on iOS devices */
  -webkit-overflow-scrolling: touch;

  /* Add a custom scrollbar for non-touch devices */
  &::-webkit-scrollbar {
    height: 10px;
    margin: 0 0 1rem 0;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--gray-lightest);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--gray-dark);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--gray);
  }
`;
