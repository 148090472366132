import SidebarItem from '@/components/SidebarItem';
import { Page } from '@/components/common';
import { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

const Reports = () => {
  const schoolId = useSelector(s => s.school.schoolId);
  const groupId = useSelector(s => s.group.groupId);

  return (
    <Page>
      <Page.Sidebar>
        <h3>Reports</h3>
        {!groupId ? (
          <SidebarItem to={`/${schoolId}`} disabled={true} end>
            Select a class to view class reports &#8599;
          </SidebarItem>
        ) : (
          <>
            <SidebarItem to={`/${schoolId}/reports`} end>
              Overview
            </SidebarItem>
            <SidebarItem to={`/${schoolId}/reports/effort`}>Effort</SidebarItem>
            <SidebarItem to={`/${schoolId}/reports/proficiency`}>
              Proficiency
            </SidebarItem>
            <SidebarItem to={`/${schoolId}/reports/growth`}>Growth</SidebarItem>
            <SidebarItem to={`/${schoolId}/reports/baseline`}>
              Baseline
            </SidebarItem>
            <SidebarItem to={`/${schoolId}/reports/group`}>Class</SidebarItem>
          </>
        )}

        <SidebarItem to={`/${schoolId}/reports/school`}>School</SidebarItem>
      </Page.Sidebar>
      <Page.Main>
        <Suspense>
          <Outlet />
        </Suspense>
      </Page.Main>
    </Page>
  );
};

export default Reports;
